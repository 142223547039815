import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Field2 from 'components/Field2'
import FieldDateOfBirth from 'components/FieldDateOfBirth'
import FormValidationNotification from 'components/FormValidationNotification'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import type { Assessment } from 'libs/assessments'
import * as assessments from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as validations from 'libs/validations'

type Props = {
  assessment: Assessment
  onSuccess: () => void
  timeValue?: string
} & Component

export default function AssessmentResultsForm({
  assessment,
  className,
  onSuccess,
  timeValue = '',
}: Props) {
  const globalContext = useGlobalContext()

  const isReps = assessment.type === assessments.ASSESSMENT_TYPE_REPS
  const isTimed = assessment.type === assessments.ASSESSMENT_TYPE_TIMED

  async function submit(values, goToProgramOnSuccess) {
    const { dateOfBirth, reps, time } = values

    let { result } = assessments.getLocallyStoredAssessmentDetails()

    if (isReps) result = reps
    if (isTimed) result = time

    if (isReps || isTimed) {
      assessments.locallyStoreAssessmentInformation(
        assessment.id,
        assessment.title,
        result,
        dateOfBirth
      )
    }

    if (!globalContext.isAuthed) {
      onSuccess()
      return
    }

    let assessmentName
    if (assessment.internalId === assessments.ASSESSMENT_STRENGTH_LOWER_BODY_INTERNAL_ID) {
      const checkinResponse = await checkinApiRequest.getUserCheckin({
        type: 'assessment_sit_stand',
        activeOnly: false,
        createIfNotExists: false,
      })
      const code = checkinResponse?.data?.code
      if (code) {
        switch (code) {
          case 'sit_stand_3_sessions': {
            assessmentName = 'Post 3 billables'
            break
          }
          case 'sit_stand_12_sessions': {
            assessmentName = 'Post 12 billables'
            break
          }
          case 'sit_stand_3_months': {
            assessmentName = 'Post 3 months'
            break
          }
          default: {
            break
          }
        }
        globalContext.analytics?.trackEvent('User submitted sit-stand assessment', {
          assessmentName,
          result,
        })
      }
    }

    await assessmentsApiRequest.submitUserResult({
      assessmentId: assessment.id,
      type: assessment.type,
      userRecord: result,
      reason: assessmentName,
    })

    await globalContext.updateUser()

    onSuccess(goToProgramOnSuccess)
  }

  function handleSubmit(values, goToProgramOnSuccess) {
    return submit(values, goToProgramOnSuccess)
  }

  const hasDateOfBirth = globalContext.user?.dateOfBirth

  return (
    <Formik
      initialValues={{
        dateOfBirth: '',
        reps: '',
        time: timeValue,
      }}
      validationSchema={Yup.object({
        dateOfBirth: hasDateOfBirth ? undefined : validations.REQUIRED,
        reps: isReps ? validations.REQUIRED : undefined,
        time: isTimed ? validations.REQUIRED : undefined,
      })}
      className={classNames('AssessmentResultsForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          {isReps && (
            <Field2
              //label={repsLabel}
              label={assessment.resultsQuestion}
              name="reps"
              inputMode="numeric"
              min={0}
              max={99}
              maxLength="2"
              placeholder="Number"
              type="number"
            />
          )}
          {isTimed && (
            <Field2
              //label={timeLabel}
              label={assessment.resultsQuestion}
              name="time"
              min={0}
              step="0.01"
            />
          )}
          {!hasDateOfBirth && <FieldDateOfBirth />}
          <Button
            color="yellow"
            disabled={formikProps.isSubmitting}
            full
            onClick={formikProps.submitForm}>
            Show my results
          </Button>
        </Form>
      )}
    </Formik>
  )
}
