import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './AssessmentResultBalanceStats.module.scss'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark---circle.svg'
import feetTogether2xImage from './images/poses/feet-together@2x.jpg'
import flamingo2xImage from './images/poses/flamingo@2x.jpg'
import staggered2xImage from './images/poses/staggered@2x.jpg'
import tandem2xImage from './images/poses/tandem@2x.jpg'

const copy = [
  'Balance can be tricky! Try a Bold class today for exercises to improve your balance.',
  'Being unable to hold staggered stance for 10 seconds can indicate higher risk of falling. Try a Bold class today for exercises to improve your balance.',
  'Being unable to hold tandem stance for 10 seconds can indicate higher risk of falling. Try a Bold class today for exercises to improve your balance.',
  'Being unable to hold flamingo stance for 10 seconds can indicate higher risk of falling. Try a Bold class today for exercises to practice and improve your balance.',
  'Great job! Keep that balance in tip top shape by taking a Bold class today.',
]

const images = [feetTogether2xImage, staggered2xImage, tandem2xImage, flamingo2xImage]

type Pose = {
  isCompleted: boolean
  title: string
}

type Props = {
  poses: Pose[]
} & Component

export default function AssessmentResultBalanceStats({ className, points, poses }: Props) {
  return (
    <div className={classNames('AssessmentResultBalanceStats', className)}>
      <Container>
        <Row>
          <div className={styles.grid}>
            {poses.map((item: Pose, index: number) => (
              <Text
                align="center"
                element="figure"
                key={item.title}
                className={styles['grid--item']}>
                <Row size="xxsmall">
                  <Image
                    src2x={images[index]}
                    full
                    aria-hidden
                    className={!item.isCompleted && styles['grid--item--image---incomplete']}
                  />
                </Row>
                <Text element="figcaption" weight="medium">
                  {item.title}
                </Text>
                {item.isCompleted && (
                  <CheckmarkCircleSvg
                    aria-label="Complete"
                    className={styles['grid--item--checkmark']}
                  />
                )}
              </Text>
            ))}
          </div>
        </Row>
        <Row size="xsmall">
          <Text element="h3" size="large" weight="semibold">
            You have completed {points} of 4 key balance poses.
          </Text>
        </Row>
        <Text element="p" flush>
          {copy[points]}
        </Text>
      </Container>
    </div>
  )
}
