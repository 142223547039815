import { Router } from '@reach/router'
import React from 'react'
import { Route } from 'components/Route'
import Assessments from 'components/pages/Assessments'
import paths from 'constants/paths'
import AssessmentExercise from 'features/assessments/components/AssessmentExercise'
import AssessmentLanding from 'features/assessments/components/AssessmentLanding'
import AssessmentResults from 'features/assessments/components/AssessmentResults'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function AssessmentsPage() {
  useEventPageVisited('Assessments')

  // prettier-ignore
  return (
    <Router>
      <Route path={paths.ASSESSMENTS} component={Assessments} />
      <Route path={`${paths.ASSESSMENTS}:assessmentInternalId`} component={AssessmentLanding} />
      <Route path={`${paths.ASSESSMENTS}:assessmentInternalId/${paths.ASSESSMENT_EXERCISE_RELATIVE}`} component={AssessmentExercise} />
      <Route path={`${paths.ASSESSMENTS}:assessmentInternalId/${paths.ASSESSMENT_RESULTS_RELATIVE}`} component={AssessmentResults} />
    </Router>
  )
}
