import classNames from 'classnames'
import React from 'react'
import ButtonWithModal, { type ButtonWithModalProps } from 'components/ButtonWithModal'
import Contact from 'components/Contact'
import ModalBox from 'components/ModalBox'

type Props = {
  children?: React.ReactNode
} & Partial<ButtonWithModalProps>

export default function ContactButton({ children = 'Contact us', className, ...props }: Props) {
  return (
    <ButtonWithModal
      {...props}
      modal={
        <ModalBox title="Contact Bold" subtext="We’re here to help!">
          <Contact />
        </ModalBox>
      }
      className={classNames('ContactButton', className)}>
      {children}
    </ButtonWithModal>
  )
}
